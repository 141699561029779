.bg_1 {
  background-color: #6f42c1 !important;
  color: #fff;
}
.bg_2 {
  background-color: cornflowerblue !important;
  color: #fff;
}
.bg_3 {
  background-color: #e05f71 !important;
  color: #fff;
}
.bg_4 {
  background-color: #f3a533 !important;
  color: #fff;
}
.color_purple {
  color: #6f42c1;
}
.icon_color_2 {
  color: #5aa4ed;
}
.icon_color_3 {
  color: #e05f71;
}

.icon_color_4 {
  color: #f3a533;
}

/* mini_cara_css */
.mini_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 20px;
  color: #000;
  margin-bottom: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.min_card_icon {
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.min_card_icon i {
  z-index: 99999;
  font-size: 22px !important;
  position: absolute;
  left: 8px;
  top: 8px;
}

.mini_card_title {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.mini_card_des {
  display: flex;
  align-items: center;
}
.mini_card_amount {
  font-size: 22px;
  font-weight: 700;
  margin-right: 3px;
}
.mini_card_des sub {
  text-transform: lowercase;
}
/* s_card_css */

.s_card {
  background-color: #fff;
  padding: 15px 18px;
  margin: 0px 0px 18px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: darkgrey;
  margin-bottom: 5px;
}
.bg_1 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_2 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_3 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_4 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.s_card_body {
}

.s_dashboard_btn {
  margin: 2px 10px;
  border: none;
  color: #fff;
  text-transform: lowercase;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
}

.gx-card-widget .ant-card-body {
  padding: 24px ;
}
.s_membar_card{

}
.s_single_membar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid  #999;
  padding-bottom: 14px;
  padding-top: 5px;
}
.personal_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personal_info img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 17px;
}

.name_id{

}
.name {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
.id ,.status{
  font-size: 10px;
  margin-top: 5px;
}
.gole{

}
.getpoint {
  font-weight: 800;
  font-size: 21px;
  letter-spacing: 1px;
}
.status{

}

.sectionTitle {
  font-weight: 600;
  font-size: 14px;
}

.sectionSubTitle {
  font-weight: 600;
  font-size: 11px;
}

.sectionDescription {
  font-weight: normal;
  font-size: 11px;
  color: #78889b;
}

.positiveTag {
  background: #62911b;
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  margin-left: 10px;
}

.negativeTag {
  background: #da1717;
  /*background: rgba(141, 201, 224, 0.7);*/
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  margin-left: 10px;
}
